<p-dialog
	(close)="close()"
	(onHide)="close()"
	[(visible)]="this.isVisible"
	[autoZIndex]="true"
	[baseZIndex]="90000"
	header="header"
>
	<ng-template pTemplate="header">
		<div *ngIf="this.currentTaskTemplate" class="heading">
			Edit | <span class="font-bold">{{ name }}</span>
		</div>
		<div *ngIf="!this.currentTaskTemplate" class="heading font-bold">Create Task</div>
	</ng-template>
	<p-toast key="{{this.toastKeyName}}" />
	<div class="container">
		<form #formDir="ngForm" [formGroup]="tasksForm">
			<div *ngIf="!this.currentTaskTemplate" class="flex justify-content-between mt-2">
				<h6>Task Name <span class="red-tick ml-1">{{ this.touchedTaskName }}</span></h6>
				<input
					pInputText
					id="taskName"
					formControlName="taskName"
					placeholder="e.g. creditcard reconcilliation"
					class="w-7"
					(change)="touched('taskName')"
				/>
			</div>

			<div class="flex justify-content-between mt-4">
				<h6 *ngIf="this.currentTaskTemplate" class="mt-2">Update Repeat</h6>
				<h6 *ngIf="!this.currentTaskTemplate" class="mt-2">Repeat <span class="red-tick ml-1">{{ this.touchedRepeat }}</span></h6>

				<p-dropdown
					inputId="type"
					[options]="this.typeOptions"
					optionLabel="displayName"
					dataKey="id"
					formControlName="schedule"
					placeholder="Select type"
					class="w-7"
					(onChange)="onTypeChange($event); touched('repeat')"
					(onBlur)="touched('repeat')"
				></p-dropdown>
			</div>

			<h6 *ngIf="this.currentTaskTemplate" class="review font-bold mt-6 mb-2">
				Update Review Stages
			</h6>
			<h6 *ngIf="!this.currentTaskTemplate" class="review font-bold mt-6 mb-2">Review Stages</h6>
			<div class="line"></div>

			<div class="flex justify-content-between mt-4">
				<h6 *ngIf="!this.currentTaskTemplate" class="mt-2">Review Groups<span class="red-tick ml-1">{{ this.touchedGroup }}</span></h6>
				<h6 *ngIf="this.currentTaskTemplate" class="mt-2">Update Review Groups</h6>

				<p-dropdown
					#reviewGroupDropdown
					inputId="group"
					formControlName="selectedReviewGroupOption"
					[options]="availableGroups"
					optionLabel="displayName"
					optionDisabled ="isDisable"
					[filter]="true"
					filterBy="displayName"
					[showClear]="true"
					placeholder="Select group"
					class="w-7"
					(onBlur)="touched('group')"
				>
					<ng-template pTemplate="selectedItem" let-group>
						<div class="flex align-items-center gap-2">
							<div>{{ group.displayName }}</div>
						</div>
					</ng-template>

					<ng-template let-group pTemplate="item">
						<div
							(click)="addGroupToStages(group.displayName, group.id, '')"
							class="flex align-items-center gap-2"
						>
							<div>
								{{ group.displayName }}
							</div>
						</div>
					</ng-template>
				</p-dropdown>
			</div>

			<div *ngFor="let stage of stages; let i = index">
				<div class="list-item">
					<div class="flex justify-content-between">
						<div class="flex">
							<div>Stage {{ i + 1 }} -</div>

							<div class="bold">{{ stage.groupName }}</div>
						</div>

						<p-button
							(click)="removeGroupFromStages(i,stage.groupId)"
							icon="pi pi-times"
						></p-button>
					</div>
				</div>
			</div>

			<h6 *ngIf="this.currentTaskTemplate" class="review font-bold mt-6 mb-2">
				Update Task Instructions (optional)
			</h6>
			<h6 *ngIf="!this.currentTaskTemplate" class="review font-bold mt-6 mb-2">
				Task Instructions (optional)
			</h6>
			<div class="line"></div>

			<div class="mt-4">
				<div class="left">
					<textarea
						formControlName="description"
						style="resize: none"
						pInputTextarea
						type="text"
						rows="6"
					></textarea>
				</div>
			</div>

			<div  class="mt-3 flex justify-content-end">
				<button
					(click)="createOrEditTask()"
					class="width p-button-rounded button-w"
					label="{{this.currentTaskTemplate? 'Edit':'Add'}}"
					pButton
					pRipple
					type="button"
					[disabled]="displayAddButton()"
				></button>
			</div>
		</form>
	</div>
</p-dialog>
