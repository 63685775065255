import { Component } from '@angular/core';
import { FollowUpStoreService } from '../../services/stores/follow-up-store.service';
import { FollowUp } from '../../domain/follow-up';
import { WidgetStoreService } from '../../services/stores/widget-store.service';
import { WidgetType } from '../../services/util/widget-type';
import { Router } from '@angular/router';
import { RowButton } from '../models/row-button';
import { BaseParent } from '../parent/base-parent';
import { TaskUtils } from '../../util/task-utils';
import { WorkflowType } from '../../domain/enums/workflow-type';
import { FormTaskService } from '../../services/form-task.service';
import { Property } from '../../domain/property';
import { PropertyStoreService } from '../../services/stores/property-store.service';

@Component({
   selector: 'app-follow-up-table',
   templateUrl: './follow-up-table.component.html',
   styleUrls: ['./follow-up-table.component.scss']
})
export class FollowUpTableComponent extends BaseParent {
   followUps: FollowUp[];
   selectedFollowUps: FollowUp[];
   isCurrentlyVisible: boolean;
   headingButtons: RowButton[];
   firstRow: number;
   private readonly WidgetType = WidgetType.FollowUp;
   private selectedProperty: Property | undefined;

   constructor(
      private followUpStore: FollowUpStoreService,
      private widgetStore: WidgetStoreService,
      private formTaskService: FormTaskService,
      private router: Router,
      private propStore: PropertyStoreService
   ) {
      super();
      propStore.currentProperty.subscribe((value) => {
         this.selectedProperty = value;
      });
      this.firstRow = 0;
      this.followUps = [];
      this.selectedFollowUps = [];
      this.isCurrentlyVisible = false;
      this.headingButtons = [
         new RowButton(
            'remove_selected',
            'Remove Selected',
            '',
            [
               'p-button-rounded',
               'p-button-success',
               'p-button-outlined',
               'p-mr-2',
               'p-mb-2'
            ],
            ['p-col-12', 'p-text-right'],
            'right'
         )
      ];
      this.followUpStore.followUps.subscribe((f) => {
         this.followUps = f;
         this.firstRow = 0;
      });
      this.widgetStore.currentVisibleWidgetPanel.subscribe((widget) => {
         this.isCurrentlyVisible = widget.type == this.WidgetType;
      });
   }

   completeSelected(): void {
      const idArray = [];
      const formIdArray = [];
      for (const i of this.selectedFollowUps) {
         if (i.type == WorkflowType.Form) {
            formIdArray.push(i.id);
         } else {
            idArray.push(i.id);
         }
      }
      this.followUpStore.completeFollowUps(idArray).subscribe((result) => {
         if (result > 0) {
            this.followUpStore.refreshStore();
         }
      });
      this.formTaskService.completeFollowUps(formIdArray).subscribe((r) => {
         if (r > 0) {
            this.followUpStore.refreshStore();
         }
      });
   }

   onButtonRowClick(id: string) {
      switch (id) {
         case 'go_to_tasks':
            this.router.navigate(['/tasks']).then();
            break;
         case 'remove_selected':
            this.completeSelected();
            break;
         default:
            break;
      }
   }

   async goToTask(formData: FollowUp, $event: MouseEvent) {
      if (this.selectedProperty) {
         $event.preventDefault();
         await TaskUtils.goToForm(formData, this.router, this.selectedProperty);
      }
   }
}
