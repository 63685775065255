export const environment = {
   production: false,
   datadog: {
      enabled: false,
      applicationId: 'ac7e7511-4774-4736-aa46-b586b7ba7fd8',
      clientToken: 'pub89c36582e2ec88702f131c18ba80ca7e',
      env: 'dev'
   },
   auth: {
      domain: 'dev-tyalls.au.auth0.com',
      clientId: 'KZ7cjofHY37rxQkpvTyHHIfmmGOYiHk7',
      audience: 'api.dev.v2.tyalls.com'
   },
   apiEndpoint: 'https://api.dev.v2.tyalls.com',
   PSPDFKit: {
      key: 'F4Une3fID7lujN6ThbJ7Q0bB2fgQNglaoy3gTvW3HbxR2jrqIHw914AtJ0BLq21IoypqYm3IoxWC3sEeyT8myGB-ESBcMchnk6E9GL0oyUwbG75GFeJO2nS7WHr2OKcoquIB_pkLnlBOBUc1bZ4tZp-bInWBKoXnR_m8SATRu8qerw628RBFN53riVvjNqkIBra89ddlc0WuAhSBG28lLwWiUdWf0sZTQRHPWBgn2oCMpHPDOdA0dvLNVpVoZ_GORW8Kiad7wBmg3vlHRw2SlkYoWmGrFt-y0-qEwC6ae1d_PT0VTTlhH6PBtU7uT7nZNGmwz-sh6-nXc_H4w0Wb1InPxulWktEGhcsI6o6x7ffnqNyK7w7BkpdkTqkex3XDxO0ChYJjUwNO3Gs9lZkL8DSCqDQ_ByjBPUPJAzU10OCVldbzh-1PqZ696i5hWexadDK29vCVM9w8Z3LdFKpwjwqbN-oKopGQR-ywgTMNSJz3dFmd9Da3QNoGoUsQJ1NxMdWTBj2s1TYx2VjtWZnlCAYh5rRxo6wb3UGVZ12o3DcXktgbcLMh5XJ1pMih7apNs21HMOMZpdj7UpdQqXGwzRxsAuRORkw41gMJa4fzDUA=',
      serverUrl: 'https://pdf.dev.v2.tyalls.com/',
      serverPem: `-----BEGIN PRIVATE KEY-----
MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQC7VJTUt9Us8cKj
MzEfYyjiWA4R4/M2bS1GB4t7NXp98C3SC6dVMvDuictGeurT8jNbvJZHtCSuYEvu
NMoSfm76oqFvAp8Gy0iz5sxjZmSnXyCdPEovGhLa0VzMaQ8s+CLOyS56YyCFGeJZ
qgtzJ6GR3eqoYSW9b9UMvkBpZODSctWSNGj3P7jRFDO5VoTwCQAWbFnOjDfH5Ulg
p2PKSQnSJP3AJLQNFNe7br1XbrhV//eO+t51mIpGSDCUv3E0DDFcWDTH9cXDTTlR
ZVEiR2BwpZOOkE/Z0/BVnhZYL71oZV34bKfWjQIt6V/isSMahdsAASACp4ZTGtwi
VuNd9tybAgMBAAECggEBAKTmjaS6tkK8BlPXClTQ2vpz/N6uxDeS35mXpqasqskV
laAidgg/sWqpjXDbXr93otIMLlWsM+X0CqMDgSXKejLS2jx4GDjI1ZTXg++0AMJ8
sJ74pWzVDOfmCEQ/7wXs3+cbnXhKriO8Z036q92Qc1+N87SI38nkGa0ABH9CN83H
mQqt4fB7UdHzuIRe/me2PGhIq5ZBzj6h3BpoPGzEP+x3l9YmK8t/1cN0pqI+dQwY
dgfGjackLu/2qH80MCF7IyQaseZUOJyKrCLtSD/Iixv/hzDEUPfOCjFDgTpzf3cw
ta8+oE4wHCo1iI1/4TlPkwmXx4qSXtmw4aQPz7IDQvECgYEA8KNThCO2gsC2I9PQ
DM/8Cw0O983WCDY+oi+7JPiNAJwv5DYBqEZB1QYdj06YD16XlC/HAZMsMku1na2T
N0driwenQQWzoev3g2S7gRDoS/FCJSI3jJ+kjgtaA7Qmzlgk1TxODN+G1H91HW7t
0l7VnL27IWyYo2qRRK3jzxqUiPUCgYEAx0oQs2reBQGMVZnApD1jeq7n4MvNLcPv
t8b/eU9iUv6Y4Mj0Suo/AU8lYZXm8ubbqAlwz2VSVunD2tOplHyMUrtCtObAfVDU
AhCndKaA9gApgfb3xw1IKbuQ1u4IF1FJl3VtumfQn//LiH1B3rXhcdyo3/vIttEk
48RakUKClU8CgYEAzV7W3COOlDDcQd935DdtKBFRAPRPAlspQUnzMi5eSHMD/ISL
DY5IiQHbIH83D4bvXq0X7qQoSBSNP7Dvv3HYuqMhf0DaegrlBuJllFVVq9qPVRnK
xt1Il2HgxOBvbhOT+9in1BzA+YJ99UzC85O0Qz06A+CmtHEy4aZ2kj5hHjECgYEA
mNS4+A8Fkss8Js1RieK2LniBxMgmYml3pfVLKGnzmng7H2+cwPLhPIzIuwytXywh
2bzbsYEfYx3EoEVgMEpPhoarQnYPukrJO4gwE2o5Te6T5mJSZGlQJQj9q4ZB2Dfz
et6INsK0oG8XVGXSpQvQh3RUYekCZQkBBFcpqWpbIEsCgYAnM3DQf3FJoSnXaMhr
VBIovic5l0xFkEHskAjFTevO86Fsz1C2aSeRKSqGFoOQ0tmJzBEs1R6KqnHInicD
TQrKhArgLXX4v3CddjfTRJkFWDbE/CkvKZNOrcf1nhaGCPspRJj2KUkj1Fhl9Cnc
dn/RsYEONbwQSjIfMPkvxF+8HQ==
-----END PRIVATE KEY-----`
   },
   productFruit: {
      enabled: true,
      workspaceCode: 'RTH7pcAH03c053UG',
      langCode: 'en'
   }
};
