import { Pipe, PipeTransform } from '@angular/core';
import { PropertyStoreService } from '../services/stores/property-store.service';
import { DateTime } from 'luxon';

@Pipe({
   name: 'DatePropertyTimezone'
})
export class DatePropertyTimezonePipe implements PipeTransform {
   currentPropertyTimezone: string;

   constructor(private _propertyStore: PropertyStoreService) {
      //Set's property Tz to the users local, this is just in case of errors or issues with getting the property
      this.currentPropertyTimezone = this.GetLocalTimezone();
      _propertyStore.currentProperty.subscribe((property) => {
         if (property && property.timeZone) {
            this.currentPropertyTimezone = property.timeZone;
         }
      });
   }

   transform(value: unknown, includeTime = false, isUtc = false): unknown {
      let formattedDate = null;

      let timeZoneId = 'Australia/Sydney';
      if (isUtc) {
         timeZoneId = 'UTC';
      }
      if (value instanceof DateTime) {
         formattedDate = value.setZone(this.currentPropertyTimezone);
      } else if (value instanceof Date) {
         formattedDate = DateTime.fromJSDate(value, {
            zone: timeZoneId
         }).setZone(this.currentPropertyTimezone);
      } else if (typeof value === 'string') {
         let format = 'yyyy-MM-dd';
         if (includeTime) {
            format = 'yyyy-MM-dd TT';
         }

         formattedDate = DateTime.fromFormat(value, format, {
            zone: timeZoneId
         });
         if (!formattedDate.isValid) {
            //Alt format as a quick fix for current issues till we update backend
            format = 'MM/dd/yyyy TT';
            formattedDate = DateTime.fromFormat(value, format, {
               zone: timeZoneId
            });
            if (!formattedDate.isValid) {
               formattedDate = DateTime.fromISO(value, { zone: timeZoneId });
            }
         }

         formattedDate = formattedDate.setZone(this.currentPropertyTimezone);
      } else {
         return '';
      }
      if (includeTime) {
         return formattedDate.toLocaleString(DateTime.DATETIME_SHORT);
      }
      return formattedDate.toLocaleString(DateTime.DATE_SHORT);
   }

   private GetLocalTimezone(): string {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
   }
}
