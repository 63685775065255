import { Component, EventEmitter, Output } from '@angular/core';
import { WidgetType } from '../../services/util/widget-type';
import { Mention } from '../../domain/mention';
import { WidgetStoreService } from '../../services/stores/widget-store.service';
import { MentionStoreService } from '../../services/stores/mention-store.service';
import { Router } from '@angular/router';
import { RowButton } from '../models/row-button';
import { BaseParent } from '../parent/base-parent';
import { TaskUtils } from '../../util/task-utils';
import { WorkflowType } from '../../domain/enums/workflow-type';
import { FormTaskService } from '../../services/form-task.service';
import { Property } from '../../domain/property';
import { PropertyStoreService } from '../../services/stores/property-store.service';

@Component({
   selector: 'app-mention-table',
   templateUrl: './mention-table.component.html',
   styleUrls: ['./mention-table.component.scss']
})
export class MentionTableComponent extends BaseParent {
   mentions: Mention[];
   selectedMentions: Mention[];
   isCurrentlyVisible: boolean;
   headingButtons: RowButton[];
   firstRow: number;
   @Output() renderComplete = new EventEmitter();
   private readonly WidgetType = WidgetType.Mention;
   private selectedProperty: Property | undefined;

   constructor(
      private mentionStore: MentionStoreService,
      private widgetStore: WidgetStoreService,
      private formTaskService: FormTaskService,
      private router: Router,
      private propStore: PropertyStoreService
   ) {
      super();
      this.firstRow = 0;
      this.mentions = [];
      this.selectedMentions = [];
      this.isCurrentlyVisible = false;
      this.headingButtons = [
         new RowButton(
            'delete_mentions',
            'Remove Selected',
            '',
            [
               'p-button-rounded',
               'p-button-success',
               'p-button-outlined',
               'p-mr-2',
               'p-mb-2'
            ],
            ['p-col-12', 'p-text-right'],
            'right'
         )
      ];
      this.mentionStore.mentions.subscribe((m) => {
         this.mentions = m;
         this.firstRow = 0;
      });
      this.widgetStore.currentVisibleWidgetPanel.subscribe((widget) => {
         this.isCurrentlyVisible = widget.type == this.WidgetType;
      });
      propStore.currentProperty.subscribe((value) => {
         this.selectedProperty = value;
      });
   }

   readSelected(): void {
      const idArray = [];

      for (const i of this.selectedMentions) {
         idArray.push(i.id);
      }
      this.mentionStore.readMentions(idArray).subscribe((result) => {
         if (result > 0) {
            this.mentionStore.refreshStore();
         }
      });
   }

   deleteSelected(): void {
      const idArray = [];
      const formIdArray = [];
      for (const i of this.selectedMentions) {
         if (i.type === WorkflowType.Form) {
            formIdArray.push(i.id);
         } else {
            idArray.push(i.id);
         }
      }
      this.mentionStore.deleteMentions(idArray).subscribe((result) => {
         if (result > 0) {
            this.mentionStore.refreshStore();
         }
      });
      this.formTaskService.completeMention(formIdArray).subscribe((r) => {
         if (r > 0) {
            this.mentionStore.refreshStore();
         }
      });
   }

   onButtonRowClick(id: string) {
      switch (id) {
         case 'go_to_tasks':
            this.router.navigate(['/tasks']).then();
            break;
         case 'mark_as_read':
            this.readSelected();
            break;
         case 'delete_mentions':
            this.deleteSelected();
            break;
         default:
            break;
      }
   }

   async goToMention(mention: Mention, $event: MouseEvent) {
      $event.preventDefault();
      if (mention.readDate) {
         await this.redirectToMention(mention);
      } else {
         if (mention.type === WorkflowType.Form) {
            this.formTaskService
               .readMention([mention.id])
               .subscribe(async (result) => {
                  if (result > 0) {
                     await this.redirectToMention(mention);
                  }
               });
         } else {
            this.mentionStore.readMentions([mention.id]).subscribe(async (result) => {
               if (result > 0) {
                  await this.redirectToMention(mention);
               }
            });
         }
      }
   }

   async redirectToMention(mention: Mention) {
      if (this.selectedProperty) {
         this.mentionStore.refreshStore();
         await TaskUtils.goToMention(mention, this.router, this.selectedProperty);
      }
   }
}
