import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   Output,
   SimpleChanges,
   ViewChild
} from '@angular/core';
import {
   AbstractControl,
   FormControl,
   FormGroup,
   ValidationErrors,
   Validators
} from '@angular/forms';
import { SortParameters } from 'src/app/pipes/domain/sort-parameters';
import { WorkflowTasksStoreService } from '../../services/stores/workflow-tasks-store.service';
import { Group } from '../../domain/group';
import { AutoRunSchedule } from '../../domain/auto-run-schedule';
import { CreateTaskRequest } from '../../services/request/create-task-request';
import { CreateStageRequest } from '../../services/request/create-stage-request';
import { UpdateTaskRequest } from '../../services/request/update-task-request';
import { Dropdown } from 'primeng/dropdown';
import { PhobosTaskTemplate } from '../../domain/phobos-task-template';
import { MessageService } from 'primeng/api';

@Component({
   selector: 'app-add-tasks-dialog',
   templateUrl: './add-tasks-dialog.component.html',
   styleUrls: ['./add-tasks-dialog.component.scss']
})
export class AddTasksDialogComponent implements OnChanges {
   name: string;
   tasksForm: FormGroup;
   typeOptions: SortParameters[] = [];
   stages: CreateStageRequest[] = [];
   availableGroups: Group[] = [];

   //Validation
   touchedTaskName: string;
   touchedRepeat: string;
   touchedGroup: string;
   showAddButton: boolean;

   @Input() isVisible: boolean = false;
   @Input() currentTaskTemplate?: PhobosTaskTemplate;
   @Input() groups: Group[] = [];
   @Input() schedules: AutoRunSchedule[] = [];
   @Input() currentProp: number | undefined;
   @Input() tasks: PhobosTaskTemplate[] = [];

   @Output() dialogClose = new EventEmitter<void>();

   @ViewChild('reviewGroupDropdown')
   reviewGroupDropdown: Dropdown | undefined;
   toastKeyName: string;

   constructor(
      private workflowTasksStoreService: WorkflowTasksStoreService,
      private messageService: MessageService
   ) {
      this.toastKeyName = 'taskDialogToast';
      this.name = '';
      this.groups = [];
      this.currentProp = 0;
      this.touchedTaskName = '';
      this.touchedRepeat = '';
      this.touchedGroup = '';
      this.showAddButton = true;
      this.tasksForm = new FormGroup({
         schedule: new FormControl(null, Validators.required),
         description: new FormControl('Add instructions'),
         taskName: new FormControl('', [
            Validators.required,
            this._uniqueNameValidator.bind(this)
         ]),
         selectedReviewGroupOption: new FormControl(null, Validators.required)
      });
      this.resetForm();
   }

   get schedule() {
      return this.tasksForm.get('schedule')!;
   }
   get description() {
      return this.tasksForm.get('description')!;
   }

   get taskName() {
      return this.tasksForm.get('taskName')!;
   }
   get selectedReviewGroupOption() {
      return this.tasksForm.get('selectedReviewGroupOption')!;
   }

   createOrEditTask(): void {
      if (this.schedule.value) {
         const scheduleId: number = this.schedule.value.id;
         const mapStages = (stages: CreateStageRequest[]) => {
            return stages.map((stage: CreateStageRequest, index: number) => {
               stage.stage = index + 1;
               return stage;
            });
         };
         if (this.currentTaskTemplate) {
            const updateTask = new UpdateTaskRequest();
            updateTask.id = this.currentTaskTemplate.id;
            updateTask.propertyId = this.currentProp;
            updateTask.scheduleId = scheduleId;
            updateTask.stages = mapStages(this.stages);
            updateTask.description = this.description.value;
            this.workflowTasksStoreService
               .sendUpdateTask(updateTask)
               .subscribe(() => {
                  this.dialogClose.emit();
               });
         } else {
            let taskType: number = 1;

            const schedule = this.schedules.find((s) => s.id == scheduleId);
            if (schedule && schedule.displayName.toLowerCase().includes('daily')) {
               taskType = 0;
            }

            const task = new CreateTaskRequest();
            task.propertyId = this.currentProp;
            task.isActive = true;
            task.isPrivate = false;
            task.name = this.taskName.value;
            task.templateId = '';
            task.isEnabledAi = true;
            task.type = taskType;
            task.scheduleId = scheduleId;
            task.stages = mapStages(this.stages);
            task.description = this.description.value;
            this.workflowTasksStoreService.sendCreateTask(task).subscribe(() => {
               this.dialogClose.emit();
            });
         }
      } else {
         console.error('Unable to create or edit task');
      }
   }

   addGroupToStages(option: string, id: number, description?: string) {
      if (!this.stages.some((g) => g.groupId == id)) {
         const stage = new CreateStageRequest();
         stage.groupId = id;
         stage.description = description;
         stage.groupName = option;
         this.stages.push(stage);
         this.selectedReviewGroupOption.setValue(
            new SortParameters('hasFile', true, 'Select group')
         );
         this.availableGroups.forEach((g) => {
            if (g.id === id) {
               g.isDisable = true;
            }
         });
      }
   }

   removeGroupFromStages(index: number, groupId: number) {
      this.stages.splice(index, 1);
      const group = this.availableGroups.find((s) => s.id === groupId);
      if (group) {
         group.isDisable = false;
      }
   }

   close() {
      this.isVisible = false;
      this.resetForm();
      this.dialogClose.emit();
   }

   resetForm(): void {
      this.selectedReviewGroupOption.setValue(
         new SortParameters('hasFile', true, 'Select group')
      );
      if (this.reviewGroupDropdown) {
         this.reviewGroupDropdown.selectedOption = undefined;
      }
      this.updateAvailableGroups();
      this.stages = [];
      this.tasksForm.reset();
      this.taskName.setValue('');
      this.description.setValue('');
      this.description.enable();
      this.schedule.enable();
   }

   updateAvailableGroups(): void {
      this.availableGroups = this.groups.map((obj) => ({ ...obj }));
      this.availableGroups = this.availableGroups.filter(
         (group) => group && group.displayName && group.isActive
      );
   }

   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   ngOnChanges(changes: SimpleChanges): void {
      this.typeOptions = [];
      this.updateAvailableGroups();
      this.resetForm();
      if (this.currentTaskTemplate) {
         //TODO: This needs to be removed when we move to editable schedules
         this.schedule.disable();
         this.description.setValue(this.currentTaskTemplate.description);
         this.name = this.currentTaskTemplate.name;
         this.taskName.setValue(this.currentTaskTemplate.name);
         if (this.currentTaskTemplate.autoRunScheduleId) {
            this.tasksForm.patchValue({
               schedule: {
                  id: this.currentTaskTemplate.autoRunScheduleId
               }
            });
         }
         for (const stage of this.currentTaskTemplate.stages) {
            if (stage.groupId) {
               const group = this.groups.find((g) => g.id == stage.groupId);
               let groupName = '';
               if (group) {
                  groupName = group.displayName;
               }
               this.addGroupToStages(groupName, stage.groupId, stage.description);
            }
         }
         if (!this.currentTaskTemplate.isDefaultTemplate) {
            this.description.disable();
            this.schedule.disable();
         }
      }
      for (const schedule of this.schedules) {
         this.typeOptions.push(
            new SortParameters(
               schedule.taskName,
               true,
               schedule.displayName,
               schedule.id
            )
         );
      }
   }

   private _uniqueNameValidator(_control: AbstractControl): ValidationErrors | null {
      if (_control.touched) {
         const foundTask = this.tasks.find((task) => {
            return (
               task.name && task.name.toLowerCase() === _control.value.toLowerCase()
            );
         });
         if (foundTask) {
            this.messageService.add({
               key: this.toastKeyName,
               severity: 'warn',
               summary: 'Task name already exists',
               detail: 'Names must be unique'
            });
            return { duplicate: true };
         }
      }
      return null;
   }

   touched($event: string): void {
      if ($event === 'taskName' && this.taskName.value === '') {
         this.touchedTaskName = '*required';
      } else if ($event === 'taskName') {
         this.touchedTaskName = '';
      }

      if (
         $event === 'repeat' &&
         this.schedule.value?.displayName === 'Select repeat'
      ) {
         this.touchedRepeat = '*required';
      } else if ($event === 'repeat' || this.stages.length === 0) {
         this.touchedRepeat = '';
      }

      if ($event === 'group' && this.stages.length === 0) {
         this.touchedGroup = '*required';
      } else if ($event === 'group') {
         this.touchedGroup = '';
      }

      this.showAddButton = this.displayAddButton();
   }

   displayAddButton(): boolean {
      return !this.tasksForm.valid || this.stages.length === 0;
   }

   onTypeChange($event: any): void {
      if ($event.value.propertyName.toLowerCase().includes('daily')) {
         this.messageService.add({
            key: this.toastKeyName,
            severity: 'info',
            summary: 'Daily Tasks',
            detail: 'Will run at 8pm local time'
         });
      }

      if ($event.value.propertyName.toLowerCase().includes('weekly')) {
         this.messageService.add({
            key: this.toastKeyName,
            severity: 'info',
            summary: 'Weekly Tasks',
            detail: 'Will run on Tuesday of each week'
         });
      }

      if ($event.value.propertyName.toLowerCase().includes('monthly')) {
         this.messageService.add({
            key: this.toastKeyName,
            severity: 'info',
            summary: 'Weekly Tasks',
            detail: 'Will run on the 10th day of each month'
         });
      }

      if ($event.value.propertyName.toLowerCase().includes('qtrly')) {
         this.messageService.add({
            key: this.toastKeyName,
            severity: 'info',
            summary: 'Weekly Tasks',
            detail: 'Will run on the first day of every 3rd month'
         });
      }

      if ($event.value.propertyName.toLowerCase().includes('yearly')) {
         this.messageService.add({
            key: this.toastKeyName,
            severity: 'info',
            summary: 'Weekly Tasks',
            detail: 'Will run on the 1st June each year'
         });
      }
   }
}
