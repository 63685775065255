import { Base } from './permission/base';
import { Task } from './permission/task';

export class UserPermissions {
   //roles
   isPropAdmin?: boolean;
   isSuperAdmin?: boolean;
   //permissions
   role: Base;
   user: Base;
   taskManagement: Base;
   tasks: Task;
   forms: Base;
   groups: Base;
   hasAudit?: boolean;
   hasCreateAudit?: boolean;
   hasWorkflow?: boolean;
   hasWorkflowRun?: boolean;

   constructor() {
      this.role = new Base('roles');
      this.user = new Base('users');
      this.forms = new Base('forms');
      this.groups = new Base('groups');
      this.taskManagement = new Base('taskmgmt');
      this.tasks = new Task();
   }

   updatePermissions(permissions: string): void {
      this.role.updatePermissions(permissions);
      this.user.updatePermissions(permissions);
      this.tasks.updatePermissions(permissions);
      this.forms.updatePermissions(permissions);
      this.groups.updatePermissions(permissions);
      this.taskManagement.updatePermissions(permissions);
   }
}
