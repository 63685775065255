<p-table
   #dtc
   [(selection)]="selectedTasks"
   [paginator]="true"
   [rows]="20"
   [showCurrentPageReport]="true"
   [value]="taskList"
   dataKey="processId"
   styleClass="p-datatable-tyalls p-datatable-gridlines p-datatable-striped datatable-sm"
>
   <ng-template pTemplate="header">
      <tr>
         <th pSortableColumn="name">
            Workflow Name
            <p-sortIcon field="name"></p-sortIcon>
         </th>
         <th pSortableColumn="isComplete">
            Status
            <p-sortIcon field="isComplete"></p-sortIcon>
         </th>
         <th pSortableColumn="assignedTo">
            Currently With
            <p-sortIcon field="assignedTo"></p-sortIcon>
         </th>
         <th pSortableColumn="launchedBy">
            Launched By
            <p-sortIcon field="launchedBy"></p-sortIcon>
         </th>
         <th></th>
      </tr>
   </ng-template>
   <ng-template let-task pTemplate="body">
      <tr>
         <td class="workflow-badge-row">
            <span class="p-column-title">Name</span>
            <p-badge
               [className]="this.getWorkFlowClass(task)"
               [pTooltip]="this.getToolTipText(task)"
            ></p-badge>
            {{ task.name }}
         </td>
         <td *ngIf="task.isComplete" class="column-element-center">
            <i class="fal fa-1x fa-check green-tick"></i>
         </td>
         <td *ngIf="!task.isComplete" class="column-element-center">
            <i class="fal fa-1x fa-times red-tick"></i>
         </td>
         <td>{{ task.assignedDisplayName }}</td>
         <td>{{ task.launchedBy }}</td>
         <td class="button-col">
            <button
               (click)="this.goToTask(task, $event)"
               class="p-button-rounded"
               label="Go to Task"
               pButton
               pRipple
               type="button"
            ></button>
         </td>
      </tr>
   </ng-template>

   <ng-template pTemplate="emptymessage">
      <tr>
         <td colspan="8">No tasks found</td>
      </tr>
   </ng-template>
</p-table>
