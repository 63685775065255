<div class="p-grid container">
	<div class="card pb-6">

		<div id="mention_table">
			<div class="tyalls-table-name mb-3 flex justify-content-between pr-4">
				<h6 class="tyalls-table-heading">{{ currentProperty?.name }}</h6>
				<h6 class="tyalls-table-heading">Staff <span class="font-normal">- Daily rate check</span></h6>
			</div>
		</div>

		<form #formDir="ngForm" [formGroup]="tasksOverviewForm">
			<div class="p-field xl:flex justify-content-between mb-4 text-sm px-1">
				<div class="flex gap-2">
					<!-- Radio Buttons for Date Range -->
					<div class="p-field-radiobutton task-canvas mt-2 flex">
						<p-radioButton
							formControlName="dateRangeOption"
							inputId="current_month"
							name="dateRangeOption"
							value="current_month"
							class="mr-2 cursor-pointer"
							(click)="onDateRangeChange('current_month')"
						></p-radioButton>
						<div class="mr-1 content-center">
							<label class="cursor-pointer" for="current_month">Current Month</label>
						</div>
					</div>

					<div class="p-field-radiobutton task-canvas mt-2 flex">
						<p-radioButton
							formControlName="dateRangeOption"
							inputId="last_month"
							name="dateRangeOption"
							value="last_month"
							class="mr-2 cursor-pointer"
							(click)="onDateRangeChange('last_month')"
						></p-radioButton>
						<div class="mr-1 content-center">
							<label class="cursor-pointer" for="last_month">Last Month</label>
						</div>
					</div>

					<div class="p-field-radiobutton task-canvas mt-2 flex">
						<p-radioButton
							formControlName="dateRangeOption"
							inputId="last_week"
							name="dateRangeOption"
							value="last_week"
							class="mr-2 cursor-pointer"
							(click)="onDateRangeChange('last_week')"
						></p-radioButton>
						<div class="mr-1 content-center">
							<label class="cursor-pointer" for="last_week">Last Week</label>
						</div>
					</div>

					<div class="p-field-radiobutton task-canvas mt-2 flex">
						<p-radioButton
							formControlName="dateRangeOption"
							inputId="today"
							name="dateRangeOption"
							value="today"
							class="mr-2 cursor-pointer"
							(click)="onDateRangeChange('today')"
						></p-radioButton>
						<div class="mr-1 content-center">
							<label class="cursor-pointer" for="today">Today</label>
						</div>
					</div>
				</div>

				<div class="flex justify-content-end gap-4">
					<div class="w-13rem mt-2">
						<p-dropdown
							[options]="taskViewOptions"
							optionLabel="title"
							placeholder="Select a Task"
							(onChange)="onTaskChange($event)"
						></p-dropdown>
					</div>
				</div>
			</div>
		</form>

		<div class="flex px-6 gap-2">
			<div (click)="showTaskSelection()" class="expand-orange border-round-lg cursor-pointer">
				<i
					class="fa-sharp fa-solid fa-chevron-down"
					[ngClass]="taskSelection ? 'rotate-180' : 'rotate-0'"
					style="color: #f78100;"
				></i>
			</div>

			<div class="w-full">
				<div class="expand-orange w-full chart-text font-bold mb-2 px-4">
					<div class="flex justify-content-between">
						Staff members
						<div class="flex">
							<div class="cursor-pointer text-sm mr-5 mt-1" (click)="addAllStaff()">Add all staff</div>
							<div class="cursor-pointer text-sm mt-1" (click)="resetChart()">Reset</div>
						</div>
					</div>
				</div>

				<div class="flex gap-2 w-full" [@aiResponseAnimation]="taskSelection ? 'open' : 'closed'">

					<!-- Available Tasks -->
					<div
						class="drag-canvas p-3 flex flex-wrap gap-2"
						(drop)="drop($event, 'allStaff')"
						(dragover)="allowDrop($event)"
					>
						<div *ngIf="data.length > 0"
								 class="flex flex-wrap gap-2">
							<div
								class="task-card text-xs"
								*ngFor="let staff of data"
								draggable="true"
								(dragstart)="dragStart($event, staff, 'allStaff')"
							>
								{{ staff.fullName }}
							</div>
						</div>
					</div>

					<!-- Selected Tasks -->
					<div
						class="drop-canvas p-3 flex flex-wrap gap-2"
						(drop)="drop($event, 'selectedData')"
						(dragover)="allowDrop($event)"
					>
						<div *ngIf="selectedData.length === 0" class="content-center text-center w-full">
							Drop staff member
						</div>

						<div
							*ngFor="let staff of selectedData"
							draggable="true"
							(dragstart)="dragStart($event, staff, 'selectedData')"
							class="task-card text-xs"
						>
							{{ staff.fullName }}
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Chart -->
		<div class="px-6 mt-4">
			<p-chart type="bar" [data]="chartData" [options]="options"></p-chart>
		</div>

		<!-- Data Table -->
		<div *ngIf="selectedData.length !== 0"
				 class="p-6">
			<p-table
				[value]="selectedData"
				[resizableColumns]="true"
				styleClass="p-datatable-gridlines"
			>
				<ng-template pTemplate="header">
					<tr>
						<th class="text-sm" pResizableColumn>Group</th>
						<th class="text-center text-sm" pResizableColumn>Time to Claim</th>
						<th class="text-center text-sm" pResizableColumn>Time to Review</th>
						<th class="text-center text-sm" pResizableColumn>Total</th>
					</tr>
				</ng-template>

				<ng-template pTemplate="body" let-staff>
					<tr>
						<td class="text-sm">{{ staff.fullName }}</td>
						<td class="text-center text-sm">{{ staff.avgTimeToClaim  | number: '1.2-2' }}</td>
						<td class="text-center text-sm">{{ staff.avgTimeToComplete | number: '1.2-2' }}</td>
						<td class="text-center text-sm">
							{{ (staff.avgTimeToClaim + staff.avgTimeToComplete) | number: '1.2-2' }}
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>

		<!-- AI Recommendations -->
		<div *ngIf="selectedData.length !== 0" class="px-6 gap-2 flex">
			<div (click)="showAiResponse()" class="header-ai border-round-lg cursor-pointer">
				<i
					class="fa-sharp fa-solid fa-chevron-down"
					[ngClass]="aiResponse ? 'rotate-180' : 'rotate-0'"
					style="color: #47a5de;"
				></i>
			</div>

			<div (click)="showAiResponse()"
					 class="expand-ai cursor-pointer w-full border-round-lg px-4 justify-content-between">
				<div class="flex justify-content-between">
					<div class="ai-text h-2 font-bold">Recommendations</div>
					<div class="ai-badge border-round-md px-2 font-bold">AI</div>
				</div>

				<div class="pr-2 pl-0" [@aiResponseAnimation]="aiResponse ? 'open' : 'closed'">
					<ol class="text-sm ai-text pt-4">
						<li class="line-height-3 mb-2">
							Car park occupancy has the highest time to claim (81 minutes) but the lowest time to review (19
							minutes).
							This might indicate inefficiencies in the claiming process.
						</li>
						<li class="line-height-3 mb-2">
							Minibar checks have a relatively low time to claim (56 minutes) but the highest time to review (86
							minutes).
							This could suggest bottlenecks in the review process.
						</li>
						<li class="line-height-3 mb-2">
							Daily rate check and Expense claim have moderate times for both claim and review, suggesting a balanced
							process.
						</li>
						<li class="line-height-3 mb-2">
							Vacant rooms have high times for both claim and review, indicating potential overall inefficiency.
						</li>
						<li class="line-height-3">
							Toilet checks have the lowest time to claim (55 minutes) and a relatively low time to review (27
							minutes),
							suggesting an efficient process.
						</li>
					</ol>
				</div>
			</div>
		</div>
	</div>
</div>

