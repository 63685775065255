import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PropertyStoreService } from './property-store.service';

import { LoadingOverlayStoreService } from './loading-overlay-store.service';
import { DataStoreService } from '../data-store.service';
import { LoadingState } from '../../domain/loading-state';
import { PhobosTaskTemplate } from '../../domain/phobos-task-template';
import { TaskService } from '../task.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
   providedIn: 'root'
})
export class TaskTemplateService {
   public currentPropertyId: number | undefined;
   private _taskTemplates: BehaviorSubject<PhobosTaskTemplate[]> =
      new BehaviorSubject(new Array<PhobosTaskTemplate>());
   public readonly taskTemplates: Observable<PhobosTaskTemplate[]> =
      this._taskTemplates.asObservable();

   constructor(
      private propertyStore: PropertyStoreService,
      private taskService: TaskService,
      private loadingOverlayStore: LoadingOverlayStoreService,
      private dataStoreService: DataStoreService
   ) {
      propertyStore.currentProperty.subscribe((property) => {
         this.currentPropertyId = property.id;
         this.updateData();
      });
      dataStoreService.refreshTaskDataSubject.subscribe((value) => {
         if (value.PhobosTaskTemplates) {
            this.refreshStore();
         }
      });
   }

   updateData(): void {
      if (this.currentPropertyId) {
         this.taskService.getTasksByPropertyId(this.currentPropertyId).subscribe(
            (tasks: PhobosTaskTemplate[]) => {
               const loadingState = new LoadingState();
               loadingState.PhobosTaskTemplates = false;
               this.loadingOverlayStore.setLoadingState(loadingState);
               this._taskTemplates.next(tasks);
            },
            (error: HttpErrorResponse) => {
               console.error(
                  `Unable to load tasks for property ${this.currentPropertyId}`,
                  error
               );
            }
         );
      } else {
         console.warn('currentProperty is undefined');
      }
   }

   refreshStore(): void {
      this.updateData();
   }
}
