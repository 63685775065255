<div class="p-grid">
	<div class="relative card container">
		<div *ngIf="auditReportList.length > 0" class="absolute tag-positioning">
			<div class="heading-canvas mx-auto">
				<div class="flex tag-padding">
					<h6 class="dark-purple-text font-bold mb-0">
						<span class="mr-1 font-bold tag-font-size">
							{{ this.auditReportList.length }} records:
						</span>
						<span class="text-white font-semibold tag-font-size"
							>{{ this.lastRequest?.start }}
							<span class="dark-purple-text font-bold tag-font-size">to</span
							><span></span
						></span>
						<span class="text-white font-semibold tag-font-size">
							{{ this.lastRequest?.finish }}
						</span>
					</h6>
				</div>
			</div>
		</div>

		<div id="mention_table">
			<div class="tyalls-table-name mb-3 flex">
				<h6 class="tyalls-table-heading">Audit Report<span class="font-light"> - {{currentProperty?.name}}</span></h6>
			</div>
		</div>

		<div class="mt-5">
			<form #formDir="ngForm" [formGroup]="auditReportForm">
				<div class="grid px-1">
					<div class="p-field col-12 xl:col-5">
						<div class="sm:flex">
							<h6 class="text-padding mr-3">Workflow Type</h6>
							<div class="p-field-checkbox">
								<div class="flex flex-wrap gap-3">
									<div
										class="task-canvas flex sm:justify-content-start justify-content-center gap-4 align-items-center"
									>
										<div>
											<p-radioButton
												name="workflowType"
												value="standard"
												formControlName="workflowType"
												inputId="workflowType1"
												(onClick)="updateActiveWorkflows()"
											></p-radioButton>
											<label for="workflowType1" class="ml-2 font-bold"
												>Task</label
											>
										</div>

										<div
											class="flex align-items-center"
											*ngIf="this.userPermissions.forms.admin"
										>
											<p-radioButton
												name="workflowType"
												value="form"
												formControlName="workflowType"
												inputId="workflowType2"
												(onClick)="updateActiveWorkflows()"
											></p-radioButton>
											<label for="workflowType2" class="ml-2 font-bold"
												>Form</label
											>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- Workflows -->
					<div class="p-field xl:col-7 col-12 xl:mt-0 mt-3">
						<div class="p-float-label field-margin">
							<p-multiSelect
								[options]="this.activeWorkflows"
								class="multiselect-custom"
								display="chip"
								filterBy="name"
								formControlName="workflows"
								inputId="workflow"
							>
								<ng-template let-value pTemplate="selectedItems">
									<div
										class="m-1"
										*ngFor="
											let selectedWorkflow of auditReportForm.get('workflows')!
												.value
										"
									>
										<div class="workflow-dropdown-item">
											{{ selectedWorkflow.name }}
										</div>
									</div>
									<div
										*ngIf="
											!auditReportForm.get('workflows')?.value ||
											auditReportForm.get('workflows')?.value.length === 0
										"
									>
										Select Workflows
									</div>
								</ng-template>
								<ng-template let-workflow pTemplate="item">
									<div>
										{{ workflow.name }}
									</div>
								</ng-template>
							</p-multiSelect>

							<label for="workflow">Workflow</label>
						</div>
					</div>
				</div>

				<div
					*ngIf="selectedWorkFlows.valid && selectedWorkFlows.touched"
					class="p-field md:flex gap-4 justify-content-between"
				>
					<div class="p-field-radiobutton task-canvas mt-4">
						<p-radioButton
							formControlName="dateRangeOption"
							inputId="current_month"
							name="dateRangeOption"
							value="current_month"
							class="mr-2 cursor-pointer"
						></p-radioButton>
						<label class="cursor-pointer" for="current_month"
							>Current Month</label
						>
					</div>
					<div class="p-field-radiobutton task-canvas mt-4">
						<p-radioButton
							formControlName="dateRangeOption"
							inputId="last_month"
							name="dateRangeOption"
							value="last_month"
							class="mr-2 cursor-pointer"
						></p-radioButton>
						<label class="cursor-pointer" for="last_month">Last Month</label>
					</div>
					<div class="p-field-radiobutton task-canvas mt-4">
						<p-radioButton
							formControlName="dateRangeOption"
							inputId="last_week"
							name="dateRangeOption"
							value="last_week"
							class="mr-2 cursor-pointer"
						></p-radioButton>
						<label class="cursor-pointer" for="last_week">Last Week</label>
					</div>
					<div class="p-field-radiobutton task-canvas mt-4">
						<p-radioButton
							formControlName="dateRangeOption"
							inputId="today"
							name="dateRangeOption"
							value="today"
							class="mr-2 cursor-pointer"
						></p-radioButton>
						<label class="cursor-pointer" for="today">Today</label>
					</div>
					<div class="p-field-radiobutton task-canvas mt-4">
						<p-radioButton
							formControlName="dateRangeOption"
							inputId="custom"
							name="dateRangeOption"
							value="custom"
							class="mr-2 cursor-pointer"
						></p-radioButton>
						<label class="cursor-pointer" for="custom">Custom</label>
					</div>
				</div>
				<!-- Start -->

				<div
					*ngIf="
						selectedWorkFlows.valid &&
						selectedWorkFlows.touched &&
						dateRangeOption.value === 'custom'
					"
					class="grid gap-6 mt-4 task-canvas pt-5 p-4 mx-auto"
				>
					<div class="p-field">
						<span class="p-float-label">
							<p-calendar
								[maxDate]="this.maxFilterDate"
								[minDate]="this.minFilterDate"
								[required]="true"
								[showIcon]="true"
								dateFormat="yy-mm-dd"
								formControlName="startDate"
								inputId="startDate"
							></p-calendar>
							<label for="startDate">Start Date</label>
						</span>
					</div>

					<!-- Finish -->
					<div class="p-field">
						<span class="p-float-label">
							<p-calendar
								[maxDate]="this.maxFilterDate"
								[minDate]="this.minFilterDate"
								[required]="true"
								[showIcon]="true"
								dateFormat="yy-mm-dd"
								formControlName="finishDate"
								inputId="finishedDate"
							></p-calendar>
							<label for="finishedDate">Finished Date</label>
						</span>
					</div>
				</div>

				<div class="flex justify-content-end gap-4 mt-4">
					<div class="p-field">
						<p-button
							(onClick)="this.runAudit($event)"
							[disabled]="!this.auditReportForm.value.dateRangeOption"
							[loading]="this.isRunning"
							icon="fal fa-play"
							label="Run"
						></p-button>
					</div>

					<div class="p-field">
						<p-button
							(onClick)="this.saveAuditReportToFile($event)"
							[disabled]="this.auditReportList.length === 0"
							[loading]="this.isSaving"
							icon="fal fa-save"
							label="Download Report"
						></p-button>
					</div>
				</div>
			</form>
		</div>

		<div class="mt-3 mb-3">
			<div *ngIf="auditReportList.length > 0">
				<p-table
					[value]="this.auditReportList"
					dataKey="processId"
					responsiveLayout="scroll"
				>
					<ng-template pTemplate="header">
						<tr>
							<th></th>
							<th pSortableColumn="workflowName">
								Workflow Name
								<p-sortIcon field="workflowName"></p-sortIcon>
							</th>
							<th pSortableColumn="dueDate">
								Due Date
								<p-sortIcon field="dueDate"></p-sortIcon>
							</th>
							<th pSortableColumn="dailyState">
								Type
								<p-sortIcon field="dailyState"></p-sortIcon>
							</th>
							<th pSortableColumn="status">
								Status
								<p-sortIcon field="status"></p-sortIcon>
							</th>
							<th pSortableColumn="currentlyWith">
								Currently With
								<p-sortIcon field="currentlyWith"></p-sortIcon>
							</th>
							<th></th>
						</tr>
					</ng-template>

					<ng-template
						let-auditReportList
						let-expanded="expanded"
						pTemplate="body"
					>
						<tr>
							<td>
								<button
									[icon]="
										expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
									"
									[pRowToggler]="auditReportList"
									class="p-button-text p-button-rounded p-button-plain"
									pButton
									pRipple
									type="button"
								></button>
							</td>
							<td>{{ auditReportList.workflowDisplayName }} <span *ngIf="auditReportList.dailyState === 'FORM'"> - {{auditReportList.processId}}</span></td>
							<td>
								{{
									auditReportList.dueDate | DatePropertyTimezone: false : true
								}}
							</td>
							<td>
								{{ auditReportList.dailyState }}
							</td>
							<td>{{ auditReportList.status }}</td>
							<td>{{ auditReportList.displayCurrentlyWith }}</td>
							<td class="button-col">
								<button
									[routerLink]="['/tasks', auditReportList.processId]"
									(click)="this.goToTask(auditReportList, $event)"
									class="p-button-rounded"
									label="Go to Task"
									pButton
									pRipple
									type="button"
								></button>
							</td>
						</tr>
					</ng-template>
					<ng-template let-auditReportList pTemplate="rowexpansion">
						<tr>
							<td colspan="7">
								<div class="p-p-3">
									<p-table
										[value]="auditReportList.assignments"
										dataKey="id"
										sortField="claimDateTime"
									>
										<ng-template pTemplate="header">
											<tr>
												<th>Assignee</th>
												<th pSortableColumn="claimDateTime">
													Start
													<p-sortIcon field="claimDateTime"></p-sortIcon>
												</th>
												<th pSortableColumn="finishDateTime">
													End
													<p-sortIcon field="finishDateTime"></p-sortIcon>
												</th>
											</tr>
										</ng-template>
										<ng-template let-assignment pTemplate="body">
											<tr>
												<td>{{ assignment.assigneeDisplay }}</td>
												<td *ngIf="assignment.claimDateTime">
													{{
														assignment.claimDateTime
															| DatePropertyTimezone: true : true
													}}
												</td>
												<td *ngIf="!assignment.claimDateTime">
													{{
														assignment.startDateTime
															| DatePropertyTimezone: true : true
													}}
												</td>
												<td>
													{{
														assignment.finishDateTime
															| DatePropertyTimezone: true : true
													}}
												</td>
											</tr>
										</ng-template>
										<ng-template pTemplate="emptymessage">
											<tr>
												<td colspan="6">There are no assignments</td>
											</tr>
										</ng-template>
									</p-table>
								</div>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>

			<p
				*ngIf="auditReportList.length === 0 && this.hasRun === true"
				class="text-center font-semibold mt-4 mb-4"
			>
				No report returned
			</p>
		</div>
	</div>
</div>
