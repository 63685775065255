export class Base {
   name: string;
   read: boolean;
   update: boolean;
   delete: boolean;
   create: boolean;
   admin: boolean;

   constructor(name: string) {
      this.name = name;
      this.read = false;
      this.update = false;
      this.delete = false;
      this.create = false;
      this.admin = false;
   }

   public updatePermissions(permissions: string): void {
      this.read = permissions.includes(`read:${this.name}`);
      this.delete = permissions.includes(`delete:${this.name}`);
      this.update = permissions.includes(`update:${this.name}`);
      this.create = permissions.includes(`create:${this.name}`);
      this.admin = permissions.includes(`admin:${this.name}`);
   }
}
